import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import PageSection from "../components/pageSection"

const HomePage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.order)
    .map(edge => <PageSection key={edge.node.id} post={edge.node} />)

  return (
    <div>
      <Header />
      <div className="content">
        {Posts}
      </div>
      <Footer />
    </div>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/coghyp/"} },
      sort: { order: DESC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
            order
            anchor
          }
        }
      }
    }
  }
`
